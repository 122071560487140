<script>
import Layout from "../../layouts/main";
import ShopProductsView from "@/components/product/shop-products-view.vue";

export default {
  components: {
    ShopProductsView,
    Layout
  }
}
</script>

<template>
  <Layout>
    <shop-products-view />
  </Layout>
</template>